// we're not using ES6 because of import from gatsby-node
import { wineColor } from './dictionaries/wineColor';
import { wineType } from './dictionaries/wineType';
import { wineColorSlug } from './dictionaries/wineColorSlug';
import { wineTypeSlug } from './dictionaries/wineTypeSlug';
import { wineBody } from './dictionaries/wineBody';
import { wineBodySlug } from './dictionaries/wineBodySlug';
import { wineSulfites } from './dictionaries/wineSulfites';
import { bottleSize } from './dictionaries/bottleSize';
import { bottleSizeNumeric } from './dictionaries/bottleSizeNumeric';
import { countryCode } from './dictionaries/countryCode';
import { drinkingTemperature } from './dictionaries/drinkingTemperature';

const dictionaries = {
  color: wineColor,
  colorSlug: wineColorSlug,
  type: wineType,
  typeSlug: wineTypeSlug,
  body: wineBody,
  bodySlug: wineBodySlug,
  sulfites: wineSulfites,
  bottle: bottleSize,
  bottleNumeric: bottleSizeNumeric,
  temperature: drinkingTemperature,
  countryCode,
};

export default (key, stringType) => dictionaries[stringType][key];
