import React from 'react';
import { Link } from 'gatsby';
import { globalHistory } from '@reach/router';

import { getPathFromTags, checkTagsCompatibility } from '@/utils/routing';

/**
 * accepts either a tag object or array or a tag-type and -slug directly
 * the target path is generated from the tags passed in
 * tags are passed as state to the router location so they can be consumed by the navigation bar etc.
 */
export default ({
  type = null,
  slug = null,
  tags = null,
  extend = false,
  ...props
}) => {
  let sanitizedTags =
    type && slug && (!tags || tags.length === 0) ? { type, slug } : tags;

  // make sure tags is an array, even if only one tag is passed
  sanitizedTags = Array.isArray(sanitizedTags)
    ? sanitizedTags
    : [sanitizedTags];

  // depending on extend prop, we extend the tags currently stored in gatsby location state or replace them
  let targetTags;

  if (extend && globalHistory.location.state) {
    const currentTags = globalHistory.location.state.tags || [];

    targetTags = [...currentTags, ...sanitizedTags];

    // check if tags are compatible with each other
    const isTagsCompatible = checkTagsCompatibility(targetTags);

    targetTags = isTagsCompatible ? targetTags : sanitizedTags;
  } else {
    targetTags = sanitizedTags;
  }

  return (
    <Link
      to={getPathFromTags(targetTags)}
      state={{
        tags: targetTags,
      }}
      {...props}
    />
  );
};
