import styled from 'styled-components';

import { visuallyHidden } from '@/styles/helpers';
import {
  h1Display,
  h2Display,
  h3Display,
  h1Extra,
  h1,
  h2,
  h3,
  h4,
  h5,
  productTitleLarge,
  productTitleSmall,
} from '@/styles/typography';

export default styled.h1.attrs(({ h, hidden }) => ({
  h: h || 1,
  hidden: hidden || false,
}))`
  ${props => props.h === 'h1Display' && h1Display}
  ${props => props.h === 'h2Display' && h2Display}
  ${props => props.h === 'h3Display' && h3Display}
  ${props => props.h === 'h1Extra' && h1Extra}
  ${props => props.h === 'productSmall' && productTitleSmall}
  ${props => props.h === 'productLarge' && productTitleLarge}
  ${props => props.h === 1 && h1}
  ${props => props.h === 2 && h2}
  ${props => props.h === 3 && h3}
  ${props => props.h === 4 && h4}
  ${props => props.h === 5 && h5}

  ${props => props.hidden && visuallyHidden}
`;
