import styled from 'styled-components';

import media from '@/styles/media';
import scale from '@/styles/scale';

import {
  buttonLabel,
  buttonLabelSmall,
  buttonLabelLarge,
} from '@/styles/typography';

export default styled.input.attrs(props => ({
  size: props.size || 'default',
}))`
  ${props => props.size === 'default' && buttonLabel}
  ${props => props.size === 'small' && buttonLabelSmall}
  ${props => props.size === 'large' && buttonLabelLarge}
  flex: 1 1 auto;
  border: 1px solid ${props => props.theme.color.base};
  border-radius: ${props => props.theme.measure.borderRadius.base};
  padding: 0 ${scale(0.75)};
  height: ${scale(3)};
  background: transparent;
  text-transform: none;
  -webkit-appearance: none;
  outline: none;

  :focus {
    box-shadow: 0 0 0 1px ${props => props.theme.color.base};
  }

  ${props =>
    props.size === 'small' &&
    `
    padding: 0 ${scale(0.625)};
    height: ${scale(2)};
  `}

  ${props =>
    props.size === 'large' &&
    `
    padding: 0 ${scale(1)};
    height: ${scale(3)};
  `}

  ${media.md`
    ${props =>
      props.size === 'small' &&
      `
      height: ${scale(2.25)};
    `}

    ${props =>
      props.size === 'large' &&
      `
      height: ${scale(4)};
    `}
  `}

  ::placeholder {
    color: ${props => props.theme.color.gray};
  }
`;
