import React from 'react';
import styled from 'styled-components';

import BacchusImage2x from '@/assets/images/bacchus@2x.png';

import media from '@/styles/media';

const Image = styled.img`
  flex: 0 0 auto;
  display: block;
  width: 67px;
  height: 70px;

  ${media.md`
    width: 134px;
    height: 140px;
  `}
`;

export default props => (
  <Image
    src={BacchusImage2x}
    width="134"
    height="140"
    alt="Bacchus"
    {...props}
  />
);
