import React from 'react';
import styled from 'styled-components';

import rem from '@/styles/rem';

const Svg = styled.svg`
  display: block;
  width: 450px;
  height: auto;

  text {
    font-family: ${props => props.theme.font.display};
    font-weight: 200;
    font-size: ${rem(52)};
    line-height: ${rem(54)};
    letter-spacing: -0.02em;
    font-variant-ligatures: none;
  }
`;

const startPoint = [0, 120];
const controlPoint = [225, -20];
const endPoint = [450, 120];

export default ({ children, ...props }) => (
  <Svg viewBox="0 0 450 120" xmlns="http://www.w3.org/2000/svg" {...props}>
    <defs>
      <path
        id="CurvedPath"
        d={`
          M ${startPoint}
          Q ${controlPoint} ${endPoint}
        `}
      />
    </defs>

    <text>
      <textPath xlinkHref="#CurvedPath" textAnchor="middle" startOffset="50%">
        {children}
      </textPath>
    </text>
  </Svg>
);
