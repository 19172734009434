import React from 'react';
import styled from 'styled-components';

import scale from '@/styles/scale';
import media from '@/styles/media';

import Icon from '@/atoms/Icon';

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  border: 0;
  border-radius: 50%;
  margin-right: ${scale(0.5)};
  width: ${scale(2.5)};
  height: ${scale(2.5)};
  background: ${props => props.theme.color.base};
  color: ${props => props.theme.color.inverted};
  cursor: pointer;
  pointer-events: all;
  outline: none;

  :hover,
  :focus {
    background: ${props => props.theme.color.grayDarker};
  }

  ${media.md`
    margin-right: ${scale(0.75)};
    width: ${scale(2.75)};
    height: ${scale(2.75)};
  `}
`;

export default ({ isMenuExpanded, ...props }) => (
  <Button {...props}>
    <Icon type={isMenuExpanded ? 'close' : 'dots'} />
  </Button>
);
