import React, { createContext, useMemo } from 'react';
import queryString from 'query-string';

import { sanitizeFilters } from '@/utils/productFilters';

const defaultFilters = {};
const ProductFiltersContext = createContext(defaultFilters);

export const ProductFiltersProvider = ({ filterQuery, ...props }) => {
  const productFilters = useMemo(
    () => sanitizeFilters(queryString.parse(filterQuery)),
    [filterQuery]
  );

  return <ProductFiltersContext.Provider value={[productFilters]} {...props} />;
};

export default ProductFiltersContext;
