import React from 'react';
import styled, { ThemeProvider } from 'styled-components';

import { ProductFiltersProvider } from '@/context/ProductFiltersContext';
import ApolloProvider from '@/context/ApolloContext';
import CartContext from '@/context/CartContext';
import { TagsDictProvider } from '@/context/TagsDictContext';

import Head from '@/components/Head';
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import CookieNotice from '@/components/CookieNotice';
import NewDomainNotice from '@/components/NewDomainNotice';

import GlobalStyles from '@/styles/global';
import media from '@/styles/media';
import scale from '@/styles/scale';
import theme from '@/styles/theme';

const Wrapper = styled.main`
  padding-top: ${scale(theme.measure.header.height[0])};

  ${media.md`
    padding-top: ${scale(theme.measure.header.height[1])};
  `}
`;

export default ({ pageContext, location, children }) => {
  const isFrontPage = location.pathname === '/';
  const headerTransparentMode = isFrontPage || pageContext.transparentHeader;

  return (
    <>
      <Head />

      <GlobalStyles background={pageContext.background} />

      <ApolloProvider>
        <TagsDictProvider>
          <ThemeProvider theme={theme}>
            <ProductFiltersProvider filterQuery={location.search}>
              <CartContext.Provider>
                <Header
                  transparentMode={headerTransparentMode}
                  defaultNavigationTags={pageContext.defaultNavigationTags}
                />

                <Wrapper>{children}</Wrapper>

                <Footer showNewsletter={!isFrontPage} />

                <CookieNotice />

                <NewDomainNotice />
              </CartContext.Provider>
            </ProductFiltersProvider>
          </ThemeProvider>
        </TagsDictProvider>
      </ApolloProvider>
    </>
  );
};
