const wineTypeSlug = {
  NON_SPARKLING: 'non-sparkling',
  SPARKLING: 'sparkling',
  CREMANT: 'cremant',
  CHAMPAGNE: 'champagne',
  PROSECCO: 'prosecco',
  CAVA: 'cava',
  PET_NAT: 'pet-nat',
};

module.exports.wineTypeSlug = wineTypeSlug;
