import React from 'react';
import styled from 'styled-components';

import NavigationLink from '@/atoms/NavigationLink';
import Icon from '@/atoms/Icon';

import media from '@/styles/media';
import scale from '@/styles/scale';
import { tagLabel, tagLabelSmall, tagLabelLarge } from '@/styles/typography';

const heights = {
  small: [1.75, 1.875],
  default: [2, 2.5],
  large: [2.25, 3.125],
};

const textStyles = {
  small: tagLabelSmall,
  default: tagLabel,
  large: tagLabelLarge,
};

const RemoveButton = styled.button`
  border: 0;
  border-radius: 50%;
  margin-left: ${scale(0.375)};
  margin-right: -${scale(0.25)};
  width: ${scale(1)};
  height: ${scale(1)};
  font-size: 0;
  line-height: 0;
  text-align: center;
  color: ${props =>
    props.appearance === 'outline'
      ? props.theme.color.primary
      : props.theme.color.inverted};
  background: ${props =>
    props.appearance === 'outline'
      ? 'rgba(242,208,24,0.4)'
      : 'rgba(255,255,255,0.3)'};
  cursor: pointer;
  outline: none;

  :hover {
    background: ${props =>
      props.appearance === 'outline'
        ? 'rgba(242,208,24,0.5)'
        : 'rgba(255,255,255,0.4)'};
  }

  svg {
    width: ${scale(0.75)};
    height: ${scale(0.75)};
  }
`;

const Tag = styled(
  // eslint-disable-next-line no-unused-vars
  ({ size = 'default', appearance = 'default', isRemovable, ...props }) => (
    <NavigationLink {...props} />
  )
)`
  ${props => textStyles[props.size]}
  display: inline-flex;
  align-items: center;
  border-radius: ${props => scale(heights[props.size][0] / 2)};
  padding: 0 ${props => scale(heights[props.size][0] / 2.5)};
  height: ${props => scale(heights[props.size][0])};
  max-width: 100%;
  background: ${props => props.theme.color.primary};

  ${media.md`
    border-radius: ${props => scale(heights[props.size][1] / 2)};
    padding: 0 ${props => scale(heights[props.size][1] / 2.5)};
    height: ${props => scale(heights[props.size][1])};
  `}

  && {
    color: ${props => props.theme.color.inverted};

    ${props =>
      !props.isRemovable &&
      `
      :active {
        color: ${props.theme.color.invertedTransparent};
      }
    `}

    ${props =>
      props.appearance === 'outline' &&
      `
      border: 2px solid ${props.theme.color.primary};
      background: ${props.theme.color.inverted};
      color: ${props.theme.color.primary};
    `}

    ${props =>
      props.appearance === 'light' &&
      `
      background: ${props.theme.color.grayLight};
      color: ${props.theme.color.base};
    `}
  }
`;

const TagLabel = styled.span`
  position: relative;
  top: -${scale(0.02)};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: 1.2;

  ${props =>
    props.size === 'small' &&
    `
    top: -${scale(0.01)};
  `}

  ${props =>
    props.size === 'large' &&
    `
    top: -${scale(0.05)};
  `}

  ${media.md`
    top: -${scale(0.05)};

    ${props =>
      props.size === 'small' &&
      `
      top: 0;
    `}

    ${props =>
      props.size === 'large' &&
      `
      top: -${scale(0.08)};
    `}
  `}
`;

export default ({
  size = 'default',
  appearance = 'default',
  children,
  isRemovable = false,
  onRemoveClick,
  ...props
}) => (
  <Tag size={size} appearance={appearance} isRemovable={isRemovable} {...props}>
    <TagLabel size={size}>{children}</TagLabel>

    {isRemovable && (
      <RemoveButton onClick={onRemoveClick} appearance={appearance}>
        <Icon type="closeSmall" />
      </RemoveButton>
    )}
  </Tag>
);
