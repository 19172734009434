import styled from 'styled-components';

import scale from '@/styles/scale';
import media from '@/styles/media';

import GridItem from './Item';
import GridContainer from './Container';

const Grid = styled.div.attrs(({ theme }) => ({
  halfGutter: theme.grid.gutter.map(g => (g ? scale(g / 2) : 'auto')),
}))`
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-left: -${props => props.halfGutter[0]};
  margin-right: -${props => props.halfGutter[0]};

  ${media.sm`
    margin-left: -${props => props.halfGutter[1]};
    margin-right: -${props => props.halfGutter[1]};
  `}

  ${media.md`
    margin-left: -${props => props.halfGutter[2]};
    margin-right: -${props => props.halfGutter[2]};
  `}

  ${media.lg`
    margin-left: -${props => props.halfGutter[3]};
    margin-right: -${props => props.halfGutter[3]};
  `}
`;

Grid.Item = GridItem;
Grid.Container = GridContainer;

export default Grid;
