import React, { useState } from 'react';
import styled from 'styled-components';

import LogoOld from '@/atoms/LogoOld';
import Button from '@/atoms/Button';
import Text from '@/atoms/Text';
import Heading from '@/atoms/Heading';

import media from '@/styles/media';
import scale from '@/styles/scale';

import useCountdown from '@/hooks/useCountdown';

const isBrowser = typeof window !== 'undefined';

const Notice = styled.div`
  position: fixed;
  z-index: 1000;
  left: 50%;
  top: 50%;
  width: 95%;
  max-width: 640px;
  max-height: 80vh;
  padding: ${scale(1)} ${scale(1)} ${scale(2)};
  border-radius: ${props => props.theme.measure.borderRadius.large};
  text-align: center;
  background: ${props => props.theme.color.bg};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
  transform: translate(-50%, -50%);

  ${media.md`
    padding: ${scale(1.5)} ${scale(1.5)} ${scale(2.5)};
  `}
`;

const Logo = styled(LogoOld)`
  margin: 0 auto ${scale(1)};
  width: 200px;
  height: auto;

  ${media.sm`
    width: 280px;
    margin-bottom: ${scale(1.5)};
  `}

  ${media.md`
    width: 360px;
    margin-bottom: ${scale(2)};
  `}
`;

const Title = styled(Heading).attrs({ h: 4 })`
  margin-bottom: ${scale(1)};
`;

const Content = styled(Text).attrs({ t: -1 })`
  margin-bottom: ${scale(2)};
`;

const AcceptButton = styled(Button)`
  margin-bottom: ${scale(1.5)};
`;

export default () => {
  if (!isBrowser) {
    return null;
  }

  // check if this is a redirection from the old domain
  const isRedirection = window.location.search.includes('wwwine');

  const [showNotice, setShowNotice] = useState(isRedirection);
  const [secondsLeft] = useCountdown(15, () => setShowNotice(false));

  if (!showNotice) {
    return null;
  }

  return (
    <Notice>
      <Logo />

      <Title as="p">
        No worries.
        <br />
        This is still the same old site – we just changed the name.
      </Title>

      <Content>
        The logo has changed and we’re about to roll-out some more adjustments
        to the look of our website. Otherwise things won’t change too much.
      </Content>

      <AcceptButton size="small" onClick={() => setShowNotice(false)}>
        continue to skincontact.com
      </AcceptButton>

      <Heading h={5}>(Continuing in {secondsLeft})</Heading>
    </Notice>
  );
};
