import React from 'react';
import styled from 'styled-components';

import { pLarge } from '@/styles/typography';

const Navigation = styled.nav`
  ul {
    list-style: none;
  }
`;

const NavLink = styled.a`
  ${pLarge}
  text-transform: uppercase;
  color: ${props => props.theme.color.base};

  :hover,
  :focus {
    border-bottom: 1px solid ${props => props.theme.color.base};
  }
`;

export default props => (
  <Navigation {...props}>
    <ul>
      <li>
        <NavLink
          target="_blank"
          href="https://www.instagram.com/skincontact_com/"
        >
          Instagram
        </NavLink>
      </li>
    </ul>
  </Navigation>
);
