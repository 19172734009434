import React, { createContext, useReducer } from 'react';

const defaultState = {
  isExpanded: false,
  isGroupExpanded: {
    region: false,
    producer: false,
    style: false,
  },
};

const DropdownMenuContext = createContext(defaultState);

export const DropdownMenuProvider = props => {
  const [state, dispatch] = useReducer((prevState, { type, group }) => {
    switch (type) {
      case 'OPEN_DROPDOWN':
        return {
          ...prevState,
          isExpanded: true,
        };
      case 'CLOSE_DROPDOWN':
        return defaultState; // close all groups
      case 'TOGGLE_DROPDOWN':
        return {
          ...defaultState, // close all groups
          isExpanded: !prevState.isExpanded,
        };
      case 'TOGGLE_GROUP':
        return {
          ...prevState,
          isGroupExpanded: {
            ...defaultState.isGroupExpanded, // close all other groups
            [group]: !prevState.isGroupExpanded[group],
          },
        };
      default:
        return prevState;
    }
  }, defaultState);

  return <DropdownMenuContext.Provider value={[state, dispatch]} {...props} />;
};

export default DropdownMenuContext;
