import React from 'react';
import styled from 'styled-components';

import Tag from '@/atoms/Tag';

import media from '@/styles/media';
import scale from '@/styles/scale';

const TagListItem = styled.li`
  flex: 0 0 auto;
  margin-right: ${scale(0.175)};

  ${media.md`
    margin-right: ${scale(0.25)};
  `}
`;

const TagList = styled.ol`
  display: flex;
  flex-wrap: nowrap;
  list-style: none;
`;

export default ({ tags, onTagRemoveClick, ...props }) => (
  <TagList {...props}>
    {tags.map((tag, i) => (
      <TagListItem key={`${tag.type}-${tag.slug}`}>
        <Tag
          as="div"
          size="default"
          appearance={tag.type === 'product' ? 'outline' : 'default'}
          isRemovable
          onRemoveClick={() => onTagRemoveClick(i)}
          {...tag}
        >
          {tag.title}
        </Tag>
      </TagListItem>
    ))}
  </TagList>
);
