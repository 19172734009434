import ITCSouvenirLightWoff1 from '@/assets/fonts/SouvenirStdLight.woff';
import ITCSouvenirLightWoff2 from '@/assets/fonts/SouvenirStdLight.woff2';
import FunktionalGroteskRegularWoff1 from '@/assets/fonts/FunktionalGroteskRegular.woff';
import FunktionalGroteskRegularWoff2 from '@/assets/fonts/FunktionalGroteskRegular.woff2';

export default `
  @import url("//hello.myfonts.net/count/3b695e");

  @font-face {
    font-family: 'ITC Souvenir';
    font-weight: 200;
    font-style: normal;
    font-stretch: normal;
    src: local('ITCSouvenir-Light'),
      url(${ITCSouvenirLightWoff2}) format('woff2'),
      url(${ITCSouvenirLightWoff1}) format('woff');
  }

  @font-face {
    font-family: 'Funktional Grotesk';
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    src: local('FunktionalGrotesk-Regular'),
      url(${FunktionalGroteskRegularWoff2}) format('woff2'),
      url(${FunktionalGroteskRegularWoff1}) format('woff');
  }
`;
