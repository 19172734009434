export default {
  breakpoints: ['40em', '52em', '76em'],
  scale: 1, // (in rem) => scale(1) = 16px

  grid: {
    // per breakpoint, in scale
    gutter: [1, 1, 2, 3],
    outerMargin: [1, 1, 2, 3],
    maxWidth: 75,
  },

  color: {
    bg: '#fff',
    base: '#000',
    inverted: '#fff',
    invertedTransparent: 'rgba(255,255,255,.8)',
    gray: 'rgba(0,0,0,0.3)',
    grayDark: 'rgba(0,0,0,0.75)',
    grayDarker: 'rgba(0,0,0,0.87)',
    grayLight: 'rgba(0,0,0,0.05)',
    primary: '#f2d018',
    secondary: '#0215a7',
  },

  font: {
    sans: "'Funktional Grotesk', sans-serif",
    serif: "'ITC Souvenir', serif",
    display: "'ITC Souvenir', serif",
  },

  measure: {
    header: {
      height: [4.25, 5.5], // xs + md
    },
    borderRadius: {
      base: '2px',
      large: '5px',
    },
  },
};
