import styled from 'styled-components';

import scale from '@/styles/scale';
import media from '@/styles/media';

export default styled.div.attrs(({ theme }) => ({
  maxWidth: scale(theme.grid.maxWidth + theme.grid.outerMargin[3]),
  outerMargin: theme.grid.outerMargin.map(m => (m ? scale(m) : 0)),
}))`
  display: block;
  position: relative;
  padding-left: ${props => props.outerMargin[0]};
  padding-right: ${props => props.outerMargin[0]};
  width: 100%;
  max-width: ${props => props.maxWidth};
  margin-left: auto;
  margin-right: auto;

  ${media.sm`
    padding-left: ${props => props.outerMargin[1]};
    padding-right: ${props => props.outerMargin[1]};
  `}

  ${media.md`
    padding-left: ${props => props.outerMargin[2]};
    padding-right: ${props => props.outerMargin[2]};
  `}

  ${media.lg`
    padding-left: ${props => props.outerMargin[3]};
    padding-right: ${props => props.outerMargin[3]};
  `}
`;
