const countryCode = {
  germany: 'de',
  france: 'fr',
  austria: 'at',
  italy: 'it',
  spain: 'es',
  georgia: 'ge',
  'czech-republic': 'cz',
  slovakia: 'sk',
  slovenia: 'si',
};

module.exports.countryCode = countryCode;
