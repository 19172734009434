import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import Icon from '@/atoms/Icon';
import NavigationLink from '@/atoms/NavigationLink';

import scale from '@/styles/scale';
import media from '@/styles/media';
import {
  buttonLabel,
  buttonLabelSmall,
  buttonLabelLarge,
} from '@/styles/typography';

const ButtonIcon = styled(Icon)`
  margin-left: -${scale(0.25)};
  padding-right: ${scale(0.125)};
`;

const Button = styled.button.attrs(props => ({
  size: props.size || 'default',
}))`
  ${props => props.size === 'default' && buttonLabel}
  ${props => props.size === 'small' && buttonLabelSmall}
  ${props => props.size === 'large' && buttonLabelLarge}
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
  border: 1px solid ${props => props.theme.color.base};
  border-radius: ${props => props.theme.measure.borderRadius.base};
  padding: 0 ${scale(1)};
  height: ${scale(3)};
  cursor: pointer;
  outline: none;

  ${props =>
    !props.disabled &&
    `
    :hover, :focus {
      border-color: ${props.theme.color.grayDarker};
      color: ${props.theme.color.grayDarker};
    }

    :active {
      color: ${props.theme.color.gray};
    }
  `}

  ${props =>
    props.size === 'small' &&
    `
    padding: 0 ${scale(0.75)};
    height: ${scale(2)};
  `}

  ${props =>
    props.size === 'large' &&
    `
    padding: 0 ${scale(1.25)};
    height: ${scale(3)};
  `}

  ${media.md`
    ${props =>
      props.size === 'small' &&
      `
      height: ${scale(2.25)};
    `}

    ${props =>
      props.size === 'large' &&
      `
      height: ${scale(4)};
    `}
  `}

  ${props =>
    props.appearance === 'primary' &&
    `
    background: ${props.theme.color.base};
    color: ${props.theme.color.inverted};

    ${
      !props.disabled
        ? `
          :hover, :focus {
            background: ${props.theme.color.grayDarker};
            color: ${props.theme.color.inverted};
          }

          :active {
            color: ${props.theme.color.gray};
          }
        `
        : ''
    }
  `}

  ${props =>
    props.appearance === 'outlines' &&
    `
    background: transparent;
    color: ${props.theme.color.base};
    border: 1px solid ${props.theme.color.base};

    ${
      !props.disabled
        ? `
          :hover, :focus {
            background: ${props.theme.color.grayDarker};
            color: ${props.theme.color.inverted};
          }

          :active {
            color: ${props.theme.color.gray};
          }
        `
        : ''
    }
  `}

  ${props =>
    props.disabled &&
    `
    cursor: default;
    opacity: 0.7;
  `}

  &&:disabled {
    cursor: default;
    opacity: 0.7;
  }
`;

export default ({
  size = 'default',
  icon = null,
  appearance = 'primary',
  to = null,
  href = null,
  tags = null,
  disabled = false,
  children,
  ...props
}) => {
  const buttonProps = {
    size,
    appearance,
    as: 'button',
    disabled,
  };

  if (tags) {
    if (!disabled) {
      buttonProps.as = NavigationLink;
      buttonProps.tags = tags;
    } else {
      buttonProps.as = 'div';
    }
  }

  if (to) {
    if (!disabled) {
      buttonProps.as = Link;
      buttonProps.to = to;
    } else {
      buttonProps.as = 'div';
    }
  }

  if (href) {
    if (!disabled) {
      buttonProps.as = 'a';
      buttonProps.href = href;
    } else {
      buttonProps.as = 'div';
    }
  }

  return (
    <Button {...buttonProps} {...props}>
      {icon && <ButtonIcon type={icon} />}
      {children}
    </Button>
  );
};
