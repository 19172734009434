import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { pLarge } from '@/styles/typography';

const Navigation = styled.nav`
  ul {
    list-style: none;
  }
`;

const NavLink = styled(Link)`
  ${pLarge}
  text-transform: uppercase;
  color: ${props => props.theme.color.base};

  :hover,
  :focus {
    border-bottom: 1px solid ${props => props.theme.color.base};
  }
`;

export default props => (
  <Navigation {...props}>
    <ul>
      <li>
        <NavLink to="/">Home</NavLink>
      </li>

      <li>
        <NavLink to="/about/">About</NavLink>
      </li>

      <li>
        <NavLink to="/shipping/">Shipping Info</NavLink>
      </li>
    </ul>
  </Navigation>
);
