import React from 'react';

import { DropdownMenuProvider } from '@/context/DropdownMenuContext';

import Header from './Header';

export default props => (
  <DropdownMenuProvider>
    <Header {...props} />
  </DropdownMenuProvider>
);
