import { useState, useEffect } from 'react';

export default (seconds, onCountdownFinished) => {
  const [secondsLeft, setSecondsLeft] = useState(seconds);

  // tick on every second until countdown is at 0, then fire finished function
  useEffect(() => {
    if (secondsLeft) {
      setTimeout(() => {
        setSecondsLeft(s => s - 1);
      }, 1000);
    } else {
      onCountdownFinished();
    }
  }, [secondsLeft, seconds]);

  return [secondsLeft];
};
