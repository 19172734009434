import styled from 'styled-components';

import { p, pSmall, pxSmall } from '@/styles/typography';

export default styled.p.attrs(({ t }) => ({
  t: t || 0,
}))`
  ${props => props.t === -2 && pxSmall}
  ${props => props.t === -1 && pSmall}
  ${props => props.t === 0 && p}
`;
