import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { globalHistory } from '@reach/router';
import { navigate } from 'gatsby';

import DropdownMenuContext from '@/context/DropdownMenuContext';
import ProductFiltersContext from '@/context/ProductFiltersContext';
import TagsDictContext from '@/context/TagsDictContext';

import { getTagsFromUrlFilters, getPathFromTags } from '@/utils/routing';

import Heading from '@/atoms/Heading';

import media from '@/styles/media';
import scale from '@/styles/scale';

import Tags from './Tags';

const Container = styled.div`
  position: relative;
  border-bottom: 1px solid ${props => props.theme.color.base};
  width: 100%;
  overflow: hidden;
  transition: all 0.25s ease-out;

  ${props =>
    props.transparentMode &&
    `
    opacity: 0;
  `}
`;

const Field = styled.div`
  display: flex;
  align-items: center;
  height: ${scale(3)};
  width: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;

  ${media.md`
    height: ${scale(3.5)};
  `}
    ::-webkit-scrollbar {
    height: 0 !important;
    width: 0 !important;
  }
`;

const Placeholder = styled(Heading).attrs({ h: 2 })`
  color: ${props => props.theme.color.gray};
`;

const addTitlesToTags = (tags = [], defaultTags, dict) =>
  tags.map(tag => {
    if (tag.title) {
      return tag;
    }

    // if the tag does not contain a title, we try to get the title from the
    // page's default tags or the dict, depending on the tag type
    if (['product', 'collection'].includes(tag.type)) {
      const matchingDefaultTags = defaultTags.filter(
        defaultTag =>
          defaultTag.slug === tag.slug && defaultTag.type === tag.type
      );

      if (matchingDefaultTags.length > 0) {
        return {
          ...tag,
          title: matchingDefaultTags[0].title,
        };
      }
    }

    if (
      typeof dict[tag.type] !== 'undefined' &&
      typeof dict[tag.type][tag.slug] !== 'undefined'
    ) {
      return {
        ...tag,
        title: dict[tag.type][tag.slug],
      };
    }

    // fallback to returning the slug as the title
    return {
      ...tag,
      title: tag.slug,
    };
  });

export default ({ defaultNavigationTags, transparentMode, ...props }) => {
  const [, dispatch] = useContext(DropdownMenuContext);
  const [productFilters] = useContext(ProductFiltersContext);
  const [tagsDict] = useContext(TagsDictContext);
  const [tags, setTags] = useState([]);
  const [isInitialized, setInitialized] = useState(false);

  const openDropdownMenu = () => {
    dispatch({
      type: 'OPEN_DROPDOWN',
    });
  };

  // when a tag is removed, navigate to the path resulting of the remaining tags
  const handleRemoveTag = i => {
    const nextTags = [...tags];
    nextTags.splice(i, 1);

    navigate(getPathFromTags(nextTags), {
      state: {
        tags: nextTags,
      },
    });
  };

  useEffect(() => {
    /**
     * On mount we first try to get the active tags from the gatsby
     * location state. If nothing is stored, we try to derive the tags
     * from the url and complete them by the default tags for that page.
     */
    if (
      !globalHistory.location.state ||
      !globalHistory.location.state.tags ||
      !globalHistory.location.state.tags.length
    ) {
      // set state to tags from URL, falling back to default tags
      globalHistory.location.state = {
        ...globalHistory.location.state,
        tags: getTagsFromUrlFilters(productFilters, defaultNavigationTags),
      };
    }
  }, []);

  useEffect(() => {
    const newTags = globalHistory.location.state
      ? addTitlesToTags(
          globalHistory.location.state.tags,
          defaultNavigationTags,
          tagsDict
        )
      : defaultNavigationTags;

    setTags(newTags.filter(tag => tag !== null));
    setInitialized(true);
  }, [defaultNavigationTags, globalHistory.location.state]);

  return (
    <Container transparentMode={transparentMode} {...props}>
      <Field>
        {tags.length ? (
          <Tags tags={tags} onTagRemoveClick={handleRemoveTag} />
        ) : (
          isInitialized && (
            <Placeholder onClick={openDropdownMenu}>
              Start browsing…
            </Placeholder>
          )
        )}
      </Field>
    </Container>
  );
};
