import { css } from 'styled-components';

import theme from '@/styles/theme';

const sizes = theme.breakpoints;
const sizeNames = ['sm', 'md', 'lg'];

// Iterate through the sizes and create a media template
const media = sizes.reduce((acc, size, i) => {
  acc[sizeNames[i]] = (...args) => css`
    @media (min-width: ${size}) {
      ${css(...args)};
    }
  `;

  acc[`below${sizeNames[i]}`] = (...args) => css`
    @media (max-width: ${size}) {
      ${css(...args)};
    }
  `;

  return acc;
}, {});

export default media;
