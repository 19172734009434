import React from 'react';
import styled from 'styled-components';

import { h5 } from '@/styles/typography';
import media from '@/styles/media';
import scale from '@/styles/scale';

const Copyright = styled.p`
  ${h5};
  margin-top: ${scale(1.5)};
  text-align: left;

  ${media.md`
    margin-top: 0;
    text-align: right;
  `}
`;

export default props => (
  <Copyright {...props}>&copy; {new Date().getFullYear()}</Copyright>
);
