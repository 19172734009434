import { css } from 'styled-components';

export const visuallyHidden = css`
  position: absolute;
  margin: -1px;
  border: 0;
  padding: 0;
  height: 1px;
  width: 1px;
  clip: rect(0 0 0 0);
  overflow: hidden;
`;

export const underline = (offset, borderWidth = 1) => css`
  box-shadow: inset 0 ${offset}px ${props => props.theme.color.bg},
    inset 0 ${offset - borderWidth}px ${props => props.theme.color.base};
`;
