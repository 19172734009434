export const getImageUrl = filename =>
  `https://skincontact.imgix.net/${filename}`;

const imageTransformations = {
  default: {
    grid: {
      ar: '1:1.4',
      fit: 'crop',
      crop: 'focalpoint',
      'fp-x': 0.5,
      'fp-y': 0.49,
      'fp-z': 1.08,
    },
    detail: {
      ar: '3:3.35',
      fit: 'crop',
      crop: 'focalpoint',
      'fp-x': 0.5,
      'fp-y': 0.49,
      'fp-z': 1.12,
    },
  },
  medium: {
    grid: {
      ar: '1:1.4',
      fit: 'crop',
      crop: 'focalpoint',
      'fp-x': 0.5,
      'fp-y': 0.485,
      'fp-z': 1.1,
    },
    detail: {
      ar: '3:3.35',
      fit: 'crop',
      crop: 'focalpoint',
      'fp-x': 0.5,
      'fp-y': 0.49,
      'fp-z': 1.12,
    },
  },
  smaller: {
    grid: {
      ar: '1:1.4',
      fit: 'crop',
      crop: 'focalpoint',
      'fp-x': 0.5,
      'fp-y': 0.48,
      'fp-z': 1,
    },
    detail: {
      ar: '2:2.78',
      fit: 'crop',
      crop: 'focalpoint',
      'fp-x': 0.5,
      'fp-y': 0.49,
      'fp-z': 1,
    },
  },
};

export const getImageTransformation = (filename, view) => {
  const filenameParts = filename.split('.')[0].split('-');

  let transformation = 'default';

  if (
    [
      'TEST',
      '200417',
      '200619',
      '200703',
      '201707',
      '201728',
      '200828',
      '200909',
      '201029',
    ].includes(filenameParts[filenameParts.length - 1])
  ) {
    transformation = 'smaller';
  }

  if (['TEST', '200409'].includes(filenameParts[filenameParts.length - 1])) {
    transformation = 'medium';
  }

  return imageTransformations[transformation][view];
};
