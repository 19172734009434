import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import Icon from '@/atoms/Icon';
import NavigationLink from '@/atoms/NavigationLink';

import scale from '@/styles/scale';
import { buttonLabel, buttonLabelSmall } from '@/styles/typography';

const ButtonIcon = styled(Icon)`
  ${props =>
    props.before
      ? `
      padding-right: ${scale(0.5)};
      `
      : `
      padding-left: ${scale(0.5)};
  `}
`;

const TextButton = styled.button`
  ${props => (props.size === 'default' ? buttonLabel : buttonLabelSmall)}
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
  border: 0;
  cursor: pointer;
  color: ${props => props.theme.color.base};
  outline: none;

  :hover,
  :focus {
    color: ${props => props.theme.color.grayDark};
  }
`;

export default ({
  size = 'default',
  icon = null,
  iconBefore = null,
  to = null,
  href = null,
  tags = null,
  hrefTarget = null,
  children,
  ...props
}) => {
  const buttonProps = {
    size,
    as: 'button',
  };

  if (tags) {
    buttonProps.as = NavigationLink;
    buttonProps.tags = tags;
  }

  if (to) {
    buttonProps.as = Link;
    buttonProps.to = to;
  }

  if (href) {
    buttonProps.as = 'a';
    buttonProps.href = href;
    buttonProps.target = hrefTarget;
  }

  return (
    <TextButton {...buttonProps} {...props}>
      {iconBefore && <ButtonIcon type={iconBefore} before />}
      {children}
      {icon && <ButtonIcon type={icon} />}
    </TextButton>
  );
};
