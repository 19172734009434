import gql from 'graphql-tag';

const checkoutContentFragment = gql`
  fragment CheckoutContent on Checkout {
    id
    completedAt
    shippingLine {
      handle
      price
      title
    }
    subtotalPrice
    totalPrice
    totalTax
    ready
    webUrl
    lineItems(first: 250) {
      edges {
        node {
          id
          title
          quantity
          variant {
            id
            price
            image {
              transformedSrc(
                crop: CENTER
                maxWidth: 117
                maxHeight: 162
                scale: 3
              )
            }
            product {
              id
              handle
              vendor
              productType
            }
          }
        }
      }
    }
  }
`;

// create a new empty checkout
export const CREATE_NEW_CHECKOUT = gql`
  mutation {
    checkoutCreate(
      input: {
        allowPartialAddresses: true
        shippingAddress: { country: "Germany", city: "Berlin" }
      }
    ) {
      checkout {
        id
      }
    }
  }
`;

// get an existing checkout by id
export const GET_CHECKOUT = gql`
  query($checkoutId: ID!) {
    node(id: $checkoutId) {
      ... on Checkout {
        ...CheckoutContent
      }
    }
  }
  ${checkoutContentFragment}
`;

// add line item
export const ADD_LINE_ITEM = gql`
  mutation($checkoutId: ID!, $variantId: ID!, $quantity: Int!) {
    checkoutLineItemsAdd(
      checkoutId: $checkoutId
      lineItems: [{ quantity: $quantity, variantId: $variantId }]
    ) {
      userErrors {
        message
        field
      }
      checkout {
        ...CheckoutContent
      }
    }
  }
  ${checkoutContentFragment}
`;

// update line item (adjust quantity)
export const UPDATE_LINE_ITEM = gql`
  mutation($checkoutId: ID!, $lineItemId: ID!, $lineItemQuantity: Int!) {
    checkoutLineItemsUpdate(
      checkoutId: $checkoutId
      lineItems: [{ id: $lineItemId, quantity: $lineItemQuantity }]
    ) {
      userErrors {
        message
        field
      }
      checkout {
        ...CheckoutContent
      }
    }
  }
  ${checkoutContentFragment}
`;

// remove line item
export const REMOVE_LINE_ITEM = gql`
  mutation($checkoutId: ID!, $lineItemId: ID!) {
    checkoutLineItemsRemove(
      checkoutId: $checkoutId
      lineItemIds: [$lineItemId]
    ) {
      userErrors {
        message
        field
      }
      checkout {
        ...CheckoutContent
      }
    }
  }
  ${checkoutContentFragment}
`;

// remove multiple line items
export const REMOVE_LINE_ITEMS = gql`
  mutation($checkoutId: ID!, $lineItemIds: [ID!]!) {
    checkoutLineItemsRemove(
      checkoutId: $checkoutId
      lineItemIds: $lineItemIds
    ) {
      userErrors {
        message
        field
      }
      checkout {
        ...CheckoutContent
      }
    }
  }
  ${checkoutContentFragment}
`;

// optimistic response helpers
const getOptimisticResponse = (oldData, lineItems, total) => ({
  __typename: 'Mutation',
  userErrors: {
    __typename: 'UserError',
    message: '',
    field: '',
  },
  checkout: {
    ...oldData.node,
    totalPrice: total.toString(),
    lineItems: {
      ...oldData.node.lineItems,
      edges: lineItems,
    },
  },
});

const getTotal = lineItems =>
  lineItems.reduce(
    (total, { node }) => total + node.quantity * parseFloat(node.variant.price),
    0
  );

// optimistic response for UPDATE_LINE_ITEM
export const optimisticUpdateLineItem = (oldData, id, quantity) => {
  const updatedLineItems = oldData.node.lineItems.edges.map(edge =>
    edge.node.id === id
      ? {
          ...edge,
          node: {
            ...edge.node,
            quantity,
          },
        }
      : edge
  );

  return {
    checkoutLineItemsUpdate: {
      ...getOptimisticResponse(
        oldData,
        updatedLineItems,
        getTotal(updatedLineItems)
      ),
    },
  };
};

// optimistic response for REMOVE_LINE_ITEM
export const optimisticRemoveLineItem = (oldData, id) => {
  const updatedLineItems = oldData.node.lineItems.edges.filter(
    edge => edge.node.id !== id
  );

  return {
    checkoutLineItemsRemove: {
      ...getOptimisticResponse(
        oldData,
        updatedLineItems,
        getTotal(updatedLineItems)
      ),
    },
  };
};
