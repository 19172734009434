import React, { useContext } from 'react';
import styled from 'styled-components';

import DropdownMenuContext from '@/context/DropdownMenuContext';

import Heading from '@/atoms/Heading';
import Icon from '@/atoms/Icon';

import TagList from '@/components/TagList';

import scale from '@/styles/scale';
import media from '@/styles/media';

const CollapsibleIcon = styled(Icon)``;

const ListHolder = styled.div`
  padding: 0 0 ${scale(1)};
`;

const Title = styled(Heading).attrs({ h: 4 })`
  margin-left: ${scale(0.125)};
  margin-top: ${scale(0.5)};
  margin-bottom: ${scale(0.5)};
`;

const CollapsibleGroupHeader = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${scale(4)};
  width: 100%;
  border: 0;
  background: none;
  cursor: pointer;
  outline: none;

  :hover,
  :focus {
    color: ${props => props.theme.color.grayDark};
  }

  ${media.md`
    height: ${scale(4.5)};
  `}
`;

const SubTitle = styled(Heading).attrs({ h: 5 })`
  margin-left: ${scale(0.125)};
  margin-top: ${scale(2)};
  margin-bottom: ${scale(0.5)};
`;

const Container = styled.div`
  ${props =>
    props.collapsible &&
    `
    border-bottom: 1px solid ${props.theme.color.base};
  `}

  ${ListHolder} {
    ${props =>
      props.collapsible &&
      `
      padding-bottom: ${scale(2.5)};
    `}

    ${props =>
      props.collapsible &&
      props.hasSubGroups &&
      `
      margin-top: ${scale(-1.5)};
    `}

    ${props =>
      props.isCollapsed &&
      `
      display: none;
    `}
  }

  ${CollapsibleIcon} {
    ${props =>
      !props.isCollapsed &&
      `
      transform: rotate(180deg);
    `}
  }
`;

export default ({
  title,
  navigationItems,
  isFeatured = false,
  collapsible,
  isCollapsed = null,
  toggleCollapsed = null,
  hasSubGroups = false,
  ...props
}) => {
  const [, dispatch] = useContext(DropdownMenuContext);

  const tagListProps = {
    size: isFeatured ? 'default' : 'small',
    appearance: 'default',
    layout: isFeatured ? 'group' : 'columns',
    onTagClick: () => dispatch({ type: 'CLOSE_DROPDOWN' }),
  };

  return (
    <Container
      collapsible={collapsible}
      hasSubGroups={hasSubGroups}
      isCollapsed={isCollapsed}
      {...props}
    >
      {collapsible ? (
        <CollapsibleGroupHeader onClick={toggleCollapsed}>
          <Title as="h3">{title}</Title>

          <CollapsibleIcon type="arrowDown" />
        </CollapsibleGroupHeader>
      ) : (
        <Title as="h3">{title}</Title>
      )}

      {hasSubGroups ? (
        <ListHolder>
          {navigationItems.map(subGroup => (
            <div key={subGroup.heading}>
              <SubTitle as="h4">{subGroup.heading}</SubTitle>

              <TagList tags={subGroup.items} {...tagListProps} />
            </div>
          ))}
        </ListHolder>
      ) : (
        <ListHolder>
          <TagList tags={navigationItems} {...tagListProps} />
        </ListHolder>
      )}
    </Container>
  );
};
