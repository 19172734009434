import React from 'react';
import styled from 'styled-components';
import { Query } from 'react-apollo';
import { Link } from 'gatsby';

import CartContext from '@/context/CartContext';

import { GET_CHECKOUT } from '@/data/checkout';

import media from '@/styles/media';
import scale from '@/styles/scale';
import { cartCount } from '@/styles/typography';

import Icon from '@/atoms/Icon';

const BagIcon = styled(Icon)`
  flex: 0 0 auto;
  width: ${scale(2.75)};
  height: ${scale(2.75)};

  ${media.md`
    margin-top: ${scale(0.5)};
    width: ${scale(4)};
    height: ${scale(4)};
  `}
`;

const Count = styled.div`
  ${cartCount}
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: ${scale(-0.75)};
  width: 100%;
  text-align: center;
  color: ${props => props.theme.color.inverted};

  ${media.md`
    margin-top: ${scale(-0.525)};
  `};
`;

const Button = styled(Link)`
  position: fixed;
  right: ${scale(0.75)};
  bottom: ${scale(0.75)};
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  border: 0;
  border-radius: 50%;
  margin-left: ${scale(0.5)};
  width: ${scale(3)};
  height: ${scale(3)};
  background: ${props => props.theme.color.bg};
  color: ${props => props.theme.color.base};
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  pointer-events: all;
  outline: none;

  :hover,
  :focus {
    color: ${props => props.theme.color.grayDark};
  }

  :active {
    ${Count} {
      color: ${props => props.theme.color.gray};
    }
  }

  ${media.md`
    position: relative;
    right: 0;
    bottom: 0;
    border-radius: 0;
    margin-left: ${scale(0.75)};
    width: ${scale(2.75)};
    height: ${scale(2.75)};
    box-shadow: none;
    background: transparent;
  `}
`;

export default props => (
  <Button to="/bag/" {...props}>
    <BagIcon type="bag" />

    <CartContext.Consumer>
      {({ checkoutId }) => (
        <>
          {checkoutId && (
            <Query
              query={GET_CHECKOUT}
              variables={{ checkoutId }}
              partialRefetch
            >
              {({ loading, data }) => {
                const qtyInCart = loading
                  ? 0
                  : data.node.lineItems.edges.reduce(
                      (acc, { node }) => acc + node.quantity,
                      0
                    );

                return <Count>{loading ? '' : qtyInCart}</Count>;
              }}
            </Query>
          )}
        </>
      )}
    </CartContext.Consumer>
  </Button>
);
