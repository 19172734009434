import React, { createContext, useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import enumTagsDict from '@/data/enumTagsDict';

const TagsDictContext = createContext({});

const getNormalizedTagsFromGraphData = graphBranch =>
  graphBranch.reduce(
    (acc, cur) => ({
      ...acc,
      [cur.slug]: cur.title,
    }),
    {}
  );

const getNormalizedTagsForEnums = enumDict =>
  enumDict.reduce(
    (acc, cur) => ({
      ...acc,
      [cur.enum]: {
        title: cur.title,
        slug: cur.slug,
      },
    }),
    {}
  );

export const TagsDictProvider = props => {
  const { graph } = useStaticQuery(graphql`
    {
      graph {
        countries(orderBy: name_ASC) {
          title: name
          slug
        }
        regions(orderBy: name_ASC) {
          title: name
          slug
          country {
            slug
          }
        }
        producers(orderBy: name_ASC) {
          title: name
          slug
        }
        wineStyles(orderBy: name_ASC) {
          title: name
          slug
        }
      }
    }
  `);

  // normalize tag data so that it's searchable by a slug
  const tagData = useMemo(() => {
    // transform regions-data to include country slug
    const regionsGraph = graph.regions.map(({ country, slug, ...region }) => ({
      ...region,
      slug: `${country.slug}/${slug}`,
    }));

    return {
      country: getNormalizedTagsFromGraphData(graph.countries),
      region: getNormalizedTagsFromGraphData(regionsGraph),
      producer: getNormalizedTagsFromGraphData(graph.producers),
      style: getNormalizedTagsFromGraphData(graph.wineStyles),
      type: getNormalizedTagsFromGraphData(enumTagsDict.type),
      body: getNormalizedTagsFromGraphData(enumTagsDict.body),
    };
  }, [graph]);

  // also get enum data searchable by enum
  const enumTagData = useMemo(
    () => ({
      type: getNormalizedTagsForEnums(enumTagsDict.type),
      body: getNormalizedTagsForEnums(enumTagsDict.body),
    }),
    [graph]
  );

  return <TagsDictContext.Provider value={[tagData, enumTagData]} {...props} />;
};

export default TagsDictContext;
