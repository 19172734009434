import styled from 'styled-components';

import { p, pLarge, pSmall, pxSmall, h2, h3, h4 } from '@/styles/typography';
import scale from '@/styles/scale';
import media from '@/styles/media';

export default styled.div`
  h2 {
    ${h2}
    margin-top: ${scale(2.5)};
    margin-bottom: ${scale(1.25)};
  }

  h3 {
    ${h3}
    margin-top: ${scale(2.5)};
    margin-bottom: ${scale(1.25)};
  }

  h4 {
    ${h4}
    margin-top: ${scale(1.25)};
    margin-bottom: ${scale(1.25)};
  }

  p, ul, ol {
    ${props => (props.large ? pLarge : p)}
    margin-bottom: ${props => (props.large ? scale(1.25) : scale(0.75))};

    :last-child {
      margin-bottom: 0;
    }

    &.small {
      ${props => (props.large ? p : pSmall)}
    }

    &.xSmall {
      ${props => (props.large ? pSmall : pxSmall)}
    }

    a {
      border-bottom: 1px solid ${props => props.theme.color.base};

      :hover, :focus {
        color: ${props => props.theme.color.grayDarker};
        border-color: ${props => props.theme.color.grayDarker};
      }
    }
  }

  li {
    margin-left: ${scale(2.25)};
  }

  div.small {
    p {
      ${props => (props.large ? p : pSmall)}
      margin-bottom: ${props => (props.large ? scale(1.25) : scale(0.75))};
    }
  }

  div.space-down {
    margin-bottom: ${scale(2)};

    ${media.md`
      margin-bottom: ${scale(4)};
    `}
  }
`;
