const bottleSize = {
  ML400: '400 ml',
  ML750: '750 ml',
  ML1000: '1 l',
  ML1500: '1,5 l',
  ML3000: '3 l',
  ML6000: '6 l',
};

module.exports.bottleSize = bottleSize;
