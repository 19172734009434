const wineType = {
  NON_SPARKLING: 'non-sparkling',
  SPARKLING: 'sparkling',
  CREMANT: 'crémant',
  CHAMPAGNE: 'champagne',
  PROSECCO: 'prosecco',
  CAVA: 'cava',
  PET_NAT: 'pet nat',
};

module.exports.wineType = wineType;
