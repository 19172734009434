import React from 'react';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from 'react-apollo';
import fetch from 'isomorphic-fetch';

const client = new ApolloClient({
  uri: `https://${
    process.env.GATSBY_SHOPIFY_STOREFRONT_HANDLE
  }.myshopify.com/api/graphql`,
  request: operation => {
    operation.setContext({
      headers: {
        'X-Shopify-Storefront-Access-Token':
          process.env.GATSBY_SHOPIFY_STOREFRONT_TOKEN,
      },
    });
  },
  fetch,
});

export default ({ children }) => (
  <ApolloProvider client={client}>{children}</ApolloProvider>
);
