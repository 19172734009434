import React, { useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';

import Input from '@/atoms/Input';
import Button from '@/atoms/Button';
import Text from '@/atoms/Text';

import scale from '@/styles/scale';
import media from '@/styles/media';

import { validEmail } from '@/utils/stringVerification';

const messageFade = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-${scale(0.5)});
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const InlineFields = styled.div`
  display: flex;
  justify-content: center;
`;

const EmailInput = styled(Input)`
  min-width: 0;
  max-width: ${props => props.maxWidth};
`;

const Submit = styled(Button)`
  margin-left: ${scale(0.375)};

  ${props =>
    props.size === 'small' &&
    `
    margin-left: ${scale(0.25)};
  `}

  ${props =>
    props.size === 'large' &&
    `
    margin-left: ${scale(0.5)};
  `}
`;

const MessageHolder = styled.div`
  margin: ${scale(1.25)} auto 0;
  max-width: 400px;

  ${props =>
    props.size === 'small' &&
    `
    margin-top: ${scale(1)};
  `}

  ${props =>
    props.size === 'large' &&
    `
    margin-top: ${scale(2)};
    max-width: 480px;
  `}

  ${media.md`
    ${props =>
      props.size === 'large' &&
      `
      margin-top: ${scale(3)};
    `}
  `}
`;

const Messages = styled.div`
  position: relative;
  text-align: center;
`;

const Message = styled(Text).attrs(props => ({
  t: props.size === 'large' ? 0 : -1,
}))`
  width: 100%;
  transition: 0.25s all ease-out;
  opacity: 0;
  animation: 0.25s ${messageFade} ease-out forwards;
  animation-delay: 0.1s;

  ${props =>
    props.base &&
    `
    opacity: 1;
    animation: none;
  `}

  ${props =>
    !props.base &&
    `
    position: absolute;
    left: 0;
    top: 0;
  `}

  ${props =>
    props.base &&
    props.hide &&
    `
    opacity: 0;
    transform: translateY(${scale(0.5)});
  `}
`;

export default ({ size, inputWidth, subscribe, status, ...props }) => {
  const emailField = useRef(null);
  const [isValidEmail, setValidEmail] = useState(true);

  const handleSubmit = e => {
    e.preventDefault();
    const email = emailField.current.value;

    if (email && validEmail(email)) {
      setValidEmail(true);

      subscribe({ EMAIL: email });
    } else {
      setValidEmail(false);
    }
  };

  let feedbackText = null;

  if (!isValidEmail) {
    feedbackText = 'Please provide a valid email address.';
  } else if (status === 'error') {
    feedbackText = 'Sorry, something went wrong! Please try again later.';
  } else if (status === 'success') {
    feedbackText =
      'Thank you for subscribing! Please check your inbox to confirm.';
  }

  return (
    <form onSubmit={handleSubmit} {...props}>
      <InlineFields>
        <EmailInput
          size={size}
          maxWidth={inputWidth}
          ref={emailField}
          type="email"
          placeholder="Your email…"
        />

        <Submit
          type="submit"
          size={size}
          disabled={['sending', 'success'].includes(status)}
        >
          Submit
        </Submit>
      </InlineFields>

      <MessageHolder size={size}>
        <Messages>
          <Message size={size} base hide={status !== null || !isValidEmail}>
            Stay up to date with the latest cellar deliveries, we’ll also let
            you know about upcoming events.
          </Message>

          {feedbackText && <Message size={size}>{feedbackText}</Message>}
        </Messages>
      </MessageHolder>
    </form>
  );
};
