import React from 'react';
import styled from 'styled-components';
import MailchimpSubscribe from 'react-mailchimp-subscribe';

import media from '@/styles/media';
import scale from '@/styles/scale';

import CurvedHeading from '@/components/CurvedHeading';

import Form from './Form';

const Container = styled.div``;

const Heading = styled(CurvedHeading)`
  margin: 0 auto ${scale(1.25)};
  width: 80%;
  max-width: ${props => (props.size === 'large' ? '500px' : '350px')};

  ${media.md`
    margin-bottom: ${props =>
      props.size === 'large' ? scale(2) : scale(1.75)};
  `}
`;

export default ({
  size = 'default',
  inputWidth = '100%',
  headline = null,
  ...props
}) => (
  <MailchimpSubscribe
    url={process.env.GATSBY_MAILCHIMP_SUBSCRIBE_URL}
    render={mailchimpProps => (
      <Container {...props}>
        {headline && <Heading size={size}>{headline}</Heading>}

        <Form size={size} inputWidth={inputWidth} {...mailchimpProps} />
      </Container>
    )}
  />
);
