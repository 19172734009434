import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { h5 } from '@/styles/typography';
import media from '@/styles/media';
import scale from '@/styles/scale';

const Navigation = styled.nav`
  ul {
    list-style: none;

    li {
      padding: ${scale(0.25)} 0;

      ${media.sm`
        padding: 0 ${scale(1.5)} 0 0;
        display: inline-block;
      `}
    }
  }
`;

const NavLink = styled(Link)`
  ${h5}
  color: ${props => props.theme.color.base};

  :hover,
  :focus {
    border-bottom: 1px solid ${props => props.theme.color.base};
  }
`;

export default props => (
  <Navigation {...props}>
    <ul>
      <li>
        <NavLink to="/imprint/">Imprint/Impressum</NavLink>
      </li>

      <li>
        <NavLink to="/privacy-policy/">Privacy/Datenschutz</NavLink>
      </li>

      <li>
        <NavLink to="/terms-conditions/">Terms/AGB</NavLink>
      </li>

      <li>
        <NavLink to="/refunds/">Refunds/Widerrufsrecht</NavLink>
      </li>
    </ul>
  </Navigation>
);
