import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from 'react-helmet';

import { getImageUrl } from '@/utils/images';

import fonts from '@/styles/fonts';

export default ({
  title,
  description,
  image,
  noindex = false,
  canonical,
  children,
}) => {
  const { site } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
          titleBase
          description
          image
        }
      }
    }
  `);

  const metaDescription = description || site.siteMetadata.description;
  const ogImage = image ? getImageUrl(image) : site.siteMetadata.image;
  const ogTitle = title || site.siteMetadata.title;

  return (
    <Helmet
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.titleBase}`}
      defaultTitle={site.siteMetadata.title}
    >
      <html lang="en" />

      <style type="text/css">{fonts}</style>

      <meta name="description" content={metaDescription} />

      <meta name="google" content="notranslate" />

      <meta name="og:title" content={ogTitle} />
      <meta name="og:description" content={metaDescription} />
      <meta property="og:image" content={ogImage} />
      <meta name="og:type" content="website" />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={ogTitle} />
      <meta name="twitter:description" content={metaDescription} />

      {noindex && <meta name="robots" content="noindex,follow" />}

      {canonical && <link rel="canonical" href={canonical} />}

      {children}
    </Helmet>
  );
};
