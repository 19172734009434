const type = [
  {
    slug: 'red',
    title: 'red',
    enum: 'RED',
  },
  {
    slug: 'white',
    title: 'white',
    enum: 'WHITE',
  },
  {
    slug: 'orange',
    title: 'orange',
    enum: 'ORANGE',
  },
  {
    slug: 'rose',
    title: 'rosé',
    enum: 'ROSE',
  },
  {
    slug: 'blend',
    title: 'blend',
    enum: 'BLEND',
  },
  {
    slug: 'sparkling',
    title: 'sparkling',
    enum: 'SPARKLING',
  },
  {
    slug: 'cremant',
    title: 'crémant',
    enum: 'CREMANT',
  },
  {
    slug: 'champagne',
    title: 'champagne',
    enum: 'CHAMPAGNE',
  },
  {
    slug: 'prosecco',
    title: 'prosecco',
    enum: 'PROSECCO',
  },
  {
    slug: 'cava',
    title: 'cava',
    enum: 'CAVA',
  },
  {
    slug: 'pet-nat',
    title: 'pét nat',
    enum: 'PET_NAT',
  },
];

const body = [
  {
    slug: 'light',
    title: 'light-bodied',
    enum: 'LIGHT',
  },
  {
    slug: 'medium',
    title: 'medium-bodied',
    enum: 'MEDIUM',
  },
  {
    slug: 'full',
    title: 'full-bodied',
    enum: 'FULL',
  },
];

export default { type, body };
