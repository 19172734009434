import React from 'react';
import styled from 'styled-components';

import scale from '@/styles/scale';

import Text from '@/atoms/Text';
import Button from '@/atoms/Button';

const Info = styled(Text)`
  margin-bottom: ${scale(0.75)};
`;

export default props => (
  <div {...props}>
    <Info>Have a question or want some help choosing wine?</Info>

    <Button
      appearance="outlines"
      size="small"
      href="mailto:service@skincontact.com"
    >
      Drop us a line
    </Button>
  </div>
);
