import React, { useContext } from 'react';
import styled from 'styled-components';

import DropdownMenuContext from '@/context/DropdownMenuContext';

import useLockBodyScroll from '@/hooks/useLockBodyScroll';

import media from '@/styles/media';
import scale from '@/styles/scale';

import NavigationBar from './NavigationBar';
import NavigationMenu from './NavigationMenu';
import MenuButton from './MenuButton';
import CartButton from './CartButton';
import ExpandableContainer from './ExpandableContainer';

const TopBar = styled.div.attrs(({ theme }) => ({
  h: theme.measure.header.height.map(height => scale(height)),
}))`
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  padding: 0 ${scale(1)} 0 ${scale(0.85)};
  height: ${props => props.h[0]};

  ${media.md`
    padding: 0 ${scale(2)};
    height: ${props => props.h[1]};
  `}
`;

export default ({
  transparentMode = false,
  defaultNavigationTags = [],
  ...props
}) => {
  const [{ isExpanded, isGroupExpanded }, dispatch] = useContext(
    DropdownMenuContext
  );

  useLockBodyScroll(isExpanded);

  const toggleDropdownMenu = () => {
    dispatch({ type: 'TOGGLE_DROPDOWN' });
  };

  const toggleDropdownGroup = group => {
    dispatch({ type: 'TOGGLE_GROUP', group });
  };

  return (
    <ExpandableContainer
      transparentMode={transparentMode}
      isExpanded={isExpanded}
      {...props}
    >
      <TopBar>
        <MenuButton isMenuExpanded={isExpanded} onClick={toggleDropdownMenu} />

        <NavigationBar
          transparentMode={transparentMode && !isExpanded}
          defaultNavigationTags={defaultNavigationTags}
        />

        <CartButton />
      </TopBar>

      <NavigationMenu
        isGroupExpanded={isGroupExpanded}
        handleGroupExpanded={toggleDropdownGroup}
      />
    </ExpandableContainer>
  );
};
