import React from 'react';
import styled from 'styled-components';

import Tag from '@/atoms/Tag';

import media from '@/styles/media';
import scale from '@/styles/scale';

const TagItem = styled(Tag)`
  transition: 0.1s transform ease-out;
`;

const TagListItem = styled.li`
  flex: 0 0 auto;
  margin-right: ${scale(0.175)};

  ${media.md`
    margin-right: ${scale(0.25)};
  `}
`;

const TagList = styled.ul`
  display: flex;
  flex-wrap: ${props => (props.layout === 'line' ? 'nowrap' : 'wrap')};
  list-style: none;

  ${TagListItem} {
    ${props =>
      props.layout !== 'line' &&
      `
      margin-top: ${scale(0.25)};
    `}

    ${props =>
      props.layout === 'columns' &&
      `
      flex: 0 0 100%;
      margin-right: 0;
    `}

    ${TagItem} {
      transform: rotate(0.00001deg);
    }

    :nth-child(2n) {
      ${TagItem} {
        ${props =>
          props.rotateTags &&
          `
          transform: rotate(1deg);
        `}

        :hover, :focus {
          transform: rotate(2deg);
        }
      }
    }

    :nth-child(2n + 1) {
      ${TagItem} {
        ${props =>
          props.rotateTags &&
          `
          transform: rotate(-1deg);
        `}

        :hover, :focus {
          transform: rotate(-2deg);
        }
      }
    }

    ${media.md`
      ${props =>
        props.layout === 'columns' &&
        `
        flex: 0 0 50%;
      `}
    `}

    ${media.lg`
      ${props =>
        props.layout === 'columns' &&
        `
        flex: 0 0 33%;
      `}
    `}
  }
`;

export default ({
  tags,
  layout = 'group',
  size = null,
  appearance = 'default',
  rotateTags = false,
  removable = false,
  onTagClick,
  onTagRemoveClick = null,
  ...props
}) => (
  <TagList layout={layout} rotateTags={rotateTags} {...props}>
    {tags.map((tag, i) => (
      <TagListItem key={`${tag.type}-${tag.slug}`}>
        <TagItem
          size={size}
          onClick={onTagClick}
          appearance={tag.type === 'product' ? 'outline' : appearance}
          isRemovable={removable}
          onRemoveClick={!onTagRemoveClick ? null : () => onTagRemoveClick(i)}
          {...tag}
        >
          {tag.title}
        </TagItem>
      </TagListItem>
    ))}
  </TagList>
);
