import React, { useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

import useLocalStorage from '@/hooks/useLocalStorage';

import Button from '@/atoms/Button';
import FormattedText from '@/atoms/FormattedText';
import Bacchus from '@/atoms/Bacchus';

import media from '@/styles/media';
import scale from '@/styles/scale';

// we're delaying the display of the notice s we're sure it doesn't flash up
// before state from localstorage is loaded.
const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(${scale(1)});
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Notice = styled.div`
  position: fixed;
  z-index: 1000;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  padding: ${scale(1)} ${scale(1)} ${scale(1.5)};
  background: ${props => props.theme.color.bg};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
  opacity: 0;
  animation: 0.2s ${fadeIn} ease-out forwards;
  animation-delay: 1s;

  ${media.md`
    left: ${scale(2)};
    right: ${scale(2)};
    bottom: ${scale(1.5)};
    border-radius: ${props => props.theme.measure.borderRadius.large};
    padding: ${scale(1.5)};
    max-width: 660px;
  `}
`;

const BacchusImage = styled(Bacchus)`
  display: none;
  margin-right: ${scale(2)};

  ${media.md`
    display: block;
  `};
`;

const AcceptButton = styled(Button)`
  margin-top: ${scale(1)};
`;

export default () => {
  const [isAccepted, setAccepted] = useLocalStorage(
    'cookies_accepted',
    undefined
  );

  useEffect(() => {
    if (typeof isAccepted === 'undefined') {
      setAccepted(false);
    }
  }, []);

  if (isAccepted === false) {
    return (
      <Notice>
        <BacchusImage />

        <div>
          <FormattedText>
            <p>
              This website uses cookies to enable a personalized shopping
              experience and to analyze traffic.
            </p>
            <p className="xSmall">
              By using this site you agree that we may store and access cookies
              on your device.
              <br />
              To find out more and learn how to opt out, please visit our
              Privacy Policy.
            </p>
          </FormattedText>

          <AcceptButton size="small" onClick={() => setAccepted(true)}>
            Accept and continue
          </AcceptButton>
        </div>
      </Notice>
    );
  }

  return null;
};
