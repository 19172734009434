import React from 'react';
import styled from 'styled-components';

import media from '@/styles/media';
import scale from '@/styles/scale';

import Grid from '@/atoms/Grid';
import Heading from '@/atoms/Heading';

import NewsletterForm from '@/components/NewsletterForm';
import PaymentOptions from '@/components/PaymentOptions';

import Navigation from './Navigation';
import CustomerService from './CustomerService';
import Legal from './Legal';
import Social from './Social';
import Copyright from './Copyright';

const Footer = styled(Grid.Container)`
  max-width: 100%;
  padding: ${scale(4.5)} ${scale(1)} ${scale(1)};

  ${media.md`
    padding: ${scale(6)} ${scale(2)} ${scale(1.5)};
  `}
`;

const Newsletter = styled(NewsletterForm)`
  margin-bottom: ${scale(3.5)};
  width: 100%;

  ${media.md`
    margin-bottom: ${scale(5)};
  `}
`;

const Col = styled(Grid.Item)`
  margin-bottom: ${scale(2)};

  ${media.md`
    margin-bottom: ${scale(4)};
  `}
`;

const ColHeading = styled(Heading).attrs({ h: 5 })`
  margin-bottom: ${scale(0.25)};

  ${media.md`
    margin-bottom: ${scale(0.5)};
  `}
`;

export default ({ showNewsletter = true, ...props }) => (
  <Footer as="footer" {...props}>
    {showNewsletter && (
      <Newsletter
        size="default"
        headline="Join our Newsletter"
        inputWidth="400px"
      />
    )}

    <Grid>
      <Col w={[1, 1 / 2, 1 / 4]}>
        <ColHeading as="h5">Resources</ColHeading>

        <Navigation />
      </Col>

      <Col w={[1, 1 / 2, 1 / 4]}>
        <ColHeading as="h5">Keep Up</ColHeading>

        <Social />
      </Col>

      <Col w={[1, 1 / 2, 1 / 2]}>
        <ColHeading as="h5">Customer Service</ColHeading>

        <CustomerService />
      </Col>

      <Col w={[1, 1 / 2, 1]}>
        <ColHeading as="h5">Checkout Options</ColHeading>

        <PaymentOptions />
      </Col>

      <Grid.Item w={[1, 1, 7 / 8]}>
        <Legal />
      </Grid.Item>

      <Grid.Item w={[1, 1, 1 / 8]}>
        <Copyright />
      </Grid.Item>
    </Grid>
  </Footer>
);
