const bottleSizeNumeric = {
  ML400: 0.4,
  ML750: 0.75,
  ML1000: 1,
  ML1500: 1.5,
  ML3000: 3,
  ML6000: 6,
};

module.exports.bottleSizeNumeric = bottleSizeNumeric;
