import { css } from 'styled-components';

import rem from '@/styles/rem';
import media from '@/styles/media';

const reset = css`
  margin: 0;
  font-weight: normal;
  text-decoration: none;

  a {
    color: ${props => props.theme.color.base};
    text-decoration: none;
  }
`;

export const h1 = css`
  ${reset}
  font-size: ${rem(34)};
  line-height: ${rem(36)};

  ${media.md`
    font-size: ${rem(48)};
    line-height: ${rem(52)};
  `}
`;

export const h1Extra = css`
  ${reset}
  font-size: ${rem(30)};
  line-height: ${rem(32)};

  ${media.md`
    font-size: ${rem(48)};
    line-height: ${rem(48)};
  `}

  ${media.lg`
    font-size: ${rem(56)};
    line-height: ${rem(56)};
  `}
`;

export const h1Display = css`
  ${reset}
  font-family: ${props => props.theme.font.display};
  font-weight: 200;
  font-size: ${rem(52)};
  line-height: ${rem(54)};
  letter-spacing: -0.01em;

  ${media.md`
    font-size: ${rem(72)};
    line-height: ${rem(74)};
  `}
`;

export const h2 = css`
  ${reset}
  font-size: ${rem(26)};
  line-height: ${rem(28)};

  ${media.md`
    font-size: ${rem(32)};
    line-height: ${rem(36)};
  `}
`;

export const h2Display = css`
  ${reset}
  font-family: ${props => props.theme.font.display};
  font-weight: 200;
  font-size: ${rem(36)};
  line-height: ${rem(38)};
  letter-spacing: -0.01em;

  ${media.md`
    font-size: ${rem(52)};
    line-height: ${rem(54)};
  `}
`;

export const h3 = css`
  ${reset}
  font-size: ${rem(22)};
  line-height: ${rem(24)};

  ${media.md`
    font-size: ${rem(26)};
    line-height: ${rem(28)};
  `}
`;

export const h3Display = css`
  ${reset}
  font-family: ${props => props.theme.font.display};
  font-weight: 200;
  font-size: ${rem(26)};
  line-height: ${rem(28)};
  letter-spacing: -0.01em;

  ${media.md`
    font-size: ${rem(36)};
    line-height: ${rem(38)};
  `}
`;

export const h4 = css`
  ${reset}
  font-size: ${rem(20)};
  line-height: ${rem(22)};

  ${media.md`
    font-size: ${rem(22)};
    line-height: ${rem(24)};
  `}
`;

export const h5 = css`
  ${reset}
  font-size: ${rem(10)};
  line-height: ${rem(12)};
  text-transform: uppercase;

  ${media.md`
    font-size: ${rem(12)};
    line-height: ${rem(14)};
  `}
`;

export const p = css`
  ${reset}
  font-size: ${rem(18)};
  line-height: ${rem(22)};
`;

export const pSmall = css`
  ${reset}
  font-size: ${rem(13)};
  line-height: ${rem(16)};

  ${media.md`
    font-size: ${rem(16)};
    line-height: ${rem(20)};
  `}
`;

export const pxSmall = css`
  ${reset}
  font-size: ${rem(12)};
  line-height: ${rem(16)};
`;

export const pLarge = css`
  ${reset}
  font-size: ${rem(20)};
  line-height: ${rem(26)};

  ${media.md`
    font-size: ${rem(22)};
    line-height: ${rem(28)};
  `}
`;

export const productTitleLarge = css`
  ${reset}
  font-family: ${props => props.theme.font.serif};
  font-size: ${rem(26)};
  line-height: ${rem(28)};
  font-weight: 200;
  text-transform: uppercase;

  ${media.md`
    font-size: ${rem(42)};
    line-height: ${rem(50)};
  `}
`;

export const productTitleSmall = css`
  ${reset}
  font-family: ${props => props.theme.font.serif};
  font-size: ${rem(14)};
  line-height: ${rem(16)};
  font-weight: 200;
  text-transform: uppercase;

  ${media.md`
    font-size: ${rem(18)};
    line-height: ${rem(20)};
  `}
`;

export const cartCount = css`
  ${reset}
  font-size: ${rem(13)};
  line-height: ${rem(24)};

  ${media.md`
    font-size: ${rem(18)};
    line-height: ${rem(24)};
  `}
`;

export const buttonLabel = css`
  ${reset}
  font-size: ${rem(20)};
  line-height: ${rem(22)};
  text-transform: uppercase;
`;

export const buttonLabelSmall = css`
  ${reset}
  font-size: ${rem(16)};
  line-height: ${rem(18)};
  text-transform: uppercase;

  ${media.md`
    font-size: ${rem(18)};
    line-height: ${rem(20)};
  `}
`;

export const buttonLabelLarge = css`
  ${reset}
  font-size: ${rem(22)};
  line-height: ${rem(24)};
  text-transform: uppercase;

  ${media.md`
    font-size: ${rem(26)};
    line-height: ${rem(28)};
  `}
`;

export const tagLabel = css`
  ${reset}
  font-family: ${props => props.theme.font.sans};
  letter-spacing: 0.01em;
  font-size: ${rem(22)};
  line-height: ${rem(22)};

  ${media.md`
    font-size: ${rem(32)};
    line-height: ${rem(32)};
  `}
`;

export const tagLabelLarge = css`
  ${reset}
  font-family: ${props => props.theme.font.sans};
  letter-spacing: 0.01em;
  font-size: ${rem(28)};
  line-height: ${rem(28)};

  ${media.md`
    font-size: ${rem(40)};
    line-height: ${rem(40)};
  `}
`;

export const tagLabelSmall = css`
  ${reset}
  font-family: ${props => props.theme.font.sans};
  letter-spacing: 0.01em;
  font-size: ${rem(20)};
  line-height: ${rem(20)};

  ${media.md`
    font-size: ${rem(22)};
    line-height: ${rem(22)};
  `}
`;
