import dict from '@/dict';

const validAttributes = ['type', 'variety', 'body', 'style'];

const wineBodyValues = {
  light: 'LIGHT',
  medium: 'MEDIUM',
  full: 'FULL',
};

const wineColors = ['red', 'white', 'orange', 'rose', 'blend'];

/*
 * Filter-functions for each type of attribute
 */
const filterFunctions = {
  type: (product, values) =>
    values.every(val => {
      // depending on value, compare to wine color or type
      if (wineColors.includes(val)) {
        return dict(product.color, 'colorSlug') === val;
      }

      return dict(product.type, 'typeSlug') === val;
    }),
  variety: (product, values) => {
    const varieties = product.wineVarieties.map(
      wineVariety => wineVariety.variety.slug
    );

    return values.every(val => varieties.includes(val));
  },
  style: (product, values) => {
    const styles = product.styles.map(style => style.slug);

    return values.every(val => styles.includes(val));
  },
  body: (product, values) =>
    values.every(val => product.body === wineBodyValues[val]),
};

/*
 * Make sure the attributes to filter by are valid
 */
export const sanitizeFilters = filters => {
  const validFilters = Object.keys(filters)
    .filter(key => validAttributes.includes(key))
    .reduce(
      (obj, key) => ({
        ...obj,
        [key]: filters[key],
      }),
      {}
    );

  return validFilters;
};

/*
 * Filter products by multiple attributes
 */
export const applyFilters = (products, filters = {}) => {
  if (typeof filters !== 'object' || filters === {}) return products;

  const filterKeys = Object.keys(filters);

  const filteredProducts = products.filter(product =>
    filterKeys.every(key => {
      const value = filters[key];
      const filterFunction = filterFunctions[key];

      // convert to array (since we could have multiple e.g. styles)
      const values = Array.isArray(value) ? value : [value];

      return filterFunction(product, values);
    })
  );

  return filteredProducts;
};
