import { createGlobalStyle } from 'styled-components';
import styledNormalize from 'styled-normalize';

export default createGlobalStyle`
  ${styledNormalize}

  html {
    height: 100%;
    font-family: 'Funktional Grotesk', sans-serif;
    font-size: 16px;
    letter-spacing: 0.01em;
    color: #000;
    background: ${props =>
      props.background ? props.background : 'transparent'};
    transition: all 0.75s ease-out;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
`;
