import React from 'react';
import styled from 'styled-components';

import Icon from '@/atoms/Icon';

import scale from '@/styles/scale';
import media from '@/styles/media';

const List = styled.ul`
  list-style: none;
  font-size: 0;

  li {
    display: inline-block;
    margin: 0 ${scale(0.75)} ${scale(0.5)} 0;

    ${media.md`
      margin-right: ${scale(1)};
    `}

    :last-child {
      margin-right: 0;
    }
  }
`;

const PaymentIcon = styled(Icon)`
  height: 16px;
  width: auto;

  ${media.md`
    height: 20px;
  `}
`;

export default props => (
  <List {...props}>
    <li>
      <PaymentIcon type="paymentPayPal" title="PayPal" />
    </li>

    <li>
      <PaymentIcon type="paymentMastercard" title="Mastercard" />
    </li>

    <li>
      <PaymentIcon type="paymentVisa" title="Visa" />
    </li>

    <li>
      <PaymentIcon type="paymentApplePay" title="Apple Pay" />
    </li>

    <li>
      <PaymentIcon type="paymentGooglePay" title="Google Pay" />
    </li>
  </List>
);
