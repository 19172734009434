import React, { useContext, useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

import DropdownMenuContext from '@/context/DropdownMenuContext';

import media from '@/styles/media';
import scale from '@/styles/scale';

import dict from '@/dict';

import TextButton from '@/atoms/TextButton';

import wineColorSlugs from '@/data/wineColorSlugs';
import wineTypeSlugs from '@/data/wineTypeSlugs';

import NavigationGroup from './Group';

const NavigationContainer = styled.div`
  padding: 0 ${scale(1)};
  background: ${props => props.theme.color.bg};
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  ${media.md`
    padding-left: ${scale(5.5)};
    padding-right: ${scale(5.5)};
  `}
`;

const Container = styled.div`
  padding-top: ${scale(1)};
  padding-bottom: ${scale(2.5)};

  ${media.md`
    padding-top: ${scale(2.5)};
  `}
`;

const Group = styled(NavigationGroup)``;

const Featured = styled.div`
  margin-bottom: ${scale(1)};

  ${media.md`
    margin-bottom: ${scale(2)};
  `}

  ${Group} {
    ${media.md`
      margin-bottom: ${scale(1)};
    `}
  }
`;

const Secondary = styled.div`
  ${Group}:first-child {
    border-top: 1px solid ${props => props.theme.color.base};
  }
`;

const HomeButton = styled(TextButton).attrs({ iconBefore: 'home' })`
  margin-top: ${scale(1.5)};

  ${media.md`
      margin-top: ${scale(3)};
    `}
`;

export default ({ isGroupExpanded, handleGroupExpanded, ...props }) => {
  const [, dispatch] = useContext(DropdownMenuContext);

  const { graph } = useStaticQuery(graphql`
    {
      graph {
        countries(orderBy: name_ASC) {
          name
          slug
          regions(
            where: {
              parentRegion: null
              producers_some: { wines_some: { status: PUBLISHED } }
            }
            orderBy: name_ASC
          ) {
            name
            slug
          }
        }
        producers(
          where: { wines_some: { status: PUBLISHED } }
          orderBy: name_ASC
        ) {
          name
          slug
          regions {
            country {
              slug
            }
          }
        }
        wineStyles(
          where: { wines_some: { status: PUBLISHED } }
          orderBy: name_ASC
        ) {
          name
          slug
        }
      }
    }
  `);

  const data = useMemo(
    () => ({
      types: [
        ...wineColorSlugs
          .filter(item => item.includeInNav)
          .map(item => ({
            title: dict(item.value, 'color'),
            type: 'type',
            slug: item.slug,
          })),
        ...wineTypeSlugs
          .filter(item => item.includeInNav)
          .map(item => ({
            title: dict(item.value, 'type'),
            type: 'type',
            slug: item.slug,
          })),
      ],
      countries: graph.countries.map(item => ({
        title: item.name,
        type: 'country',
        slug: item.slug,
      })),
      regions: graph.countries.map(country => ({
        heading: country.name,
        items: country.regions.map(item => ({
          title: item.name,
          type: 'region',
          slug: `${country.slug}/${item.slug}`,
          tags: [
            {
              type: 'country',
              slug: country.slug,
              title: country.name,
            },
            {
              type: 'region',
              slug: `${country.slug}/${item.slug}`,
              title: item.name,
            },
          ],
        })),
      })),
      producers: graph.countries.map(country => ({
        heading: country.name,
        items: graph.producers
          .filter(
            producer =>
              producer.regions.length &&
              producer.regions[0].country.slug === country.slug
          )
          .map(item => ({
            title: item.name,
            type: 'producer',
            slug: item.slug,
            tags: [
              {
                type: 'country',
                slug: country.slug,
                title: country.name,
              },
              {
                type: 'producer',
                slug: item.slug,
                title: item.name,
              },
            ],
          })),
      })),
      styles: graph.wineStyles.map(item => ({
        title: item.name,
        type: 'style',
        slug: item.slug,
      })),
    }),
    [graph]
  );

  return (
    <NavigationContainer {...props}>
      <Container>
        <Featured>
          <Group title="by Type" isFeatured navigationItems={data.types} />

          <Group
            title="by Country"
            isFeatured
            navigationItems={data.countries}
          />
        </Featured>

        <Secondary>
          <Group
            title="by Region"
            collapsible
            isCollapsed={!isGroupExpanded.region}
            toggleCollapsed={() => handleGroupExpanded('region')}
            hasSubGroups
            navigationItems={data.regions}
          />

          <Group
            title="by Producer"
            collapsible
            isCollapsed={!isGroupExpanded.producer}
            toggleCollapsed={() => handleGroupExpanded('producer')}
            hasSubGroups
            navigationItems={data.producers}
          />

          <Group
            title="by Style"
            collapsible
            isCollapsed={!isGroupExpanded.style}
            toggleCollapsed={() => handleGroupExpanded('style')}
            navigationItems={data.styles}
          />

          <HomeButton
            to="/"
            onClick={() => dispatch({ type: 'CLOSE_DROPDOWN' })}
          >
            Home
          </HomeButton>
        </Secondary>
      </Container>
    </NavigationContainer>
  );
};
