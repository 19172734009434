import queryString from 'query-string';

import { flatten } from '@/utils/arrayTransforms';

/**
 * precedenceScore: sorting score for url structure, higher number takes precedence over lower, highest score becomes base url
 * isValidBase: wether this type can be used as a base or only as a filter
 * validFiltersForBase: defines which tag types can be used as filters for corresponding base type
 * getBase: function that returns base for slug
 */
const tagTypes = {
  product: {
    precedenceScore: 100,
    isValidBase: true,
    validFiltersForBase: [
      'collection',
      'producer',
      'region',
      'country',
      'type',
      'body',
      'style',
    ],
    getBase: slug => `/wines/${slug}/`, // slug has to be of form "producer-name/product-name"
  },
  collection: {
    precedenceScore: 90,
    isValidBase: true,
    validFiltersForBase: ['type', 'body', 'style'],
    getBase: slug => `/wines/collection/${slug}/`,
  },
  producer: {
    precedenceScore: 80,
    isValidBase: true,
    validFiltersForBase: ['region', 'country', 'type', 'body', 'style'],
    getBase: slug => `/wines/${slug}/`,
  },
  region: {
    precedenceScore: 70,
    isValidBase: true,
    validFiltersForBase: ['country', 'type', 'body', 'style'],
    getBase: slug => `/wines/${slug}/`, // slug has to be of form "country-name/region-name"
  },
  country: {
    precedenceScore: 60,
    isValidBase: true,
    validFiltersForBase: ['type', 'body', 'style'],
    getBase: slug => `/wines/${slug}/`,
  },
  type: {
    precedenceScore: 50,
    isValidBase: true,
    validFiltersForBase: ['type', 'body', 'style'],
    getBase: slug => `/wines/type/${slug}/`,
  },
  body: {
    precedenceScore: 40,
    isValidBase: false,
    validFiltersForBase: ['body', 'style'],
  },
  style: {
    precedenceScore: 30,
    isValidBase: false,
    validFiltersForBase: ['body', 'style'],
  },
};

const createPath = (base, filters) => {
  // get base depending on tag type, fallback to /wines/
  const basePath = tagTypes[base.type].isValidBase
    ? tagTypes[base.type].getBase(base.slug)
    : '/wines/';

  // create object with tag/filter types as keys
  const queryFragments = filters.reduce(
    (acc, filter) => ({
      ...acc,
      [filter.type]:
        typeof acc[filter.type] === 'undefined'
          ? [filter.slug]
          : [...acc[filter.type], filter.slug],
    }),
    {}
  );

  return filters.length
    ? `${basePath}?${queryString.stringify(queryFragments)}`
    : basePath;
};

const sortTagsByPrecedence = tags => {
  // rate tags with precedence score
  const ratedTags = tags.map(tag => ({
    ...tag,
    score: tagTypes[tag.type].precedenceScore,
  }));

  // sort tags by score, higher score first
  return ratedTags.sort((a, b) => b.score - a.score);
};

// checks if tags are compatible with each other
export const checkTagsCompatibility = (tags = []) => {
  if (tags.length <= 1) {
    return true;
  }

  const sortedTags = sortTagsByPrecedence(tags);
  const [baseTag, ...filterTags] = sortedTags;

  return filterTags.every(tag =>
    tagTypes[baseTag.type].validFiltersForBase.includes(tag.type)
  );
};

// remove filter tags that aren't compatible/needed with the base type
const sanitizeFilterTags = (baseTag, filterTags) =>
  filterTags.filter(tag =>
    tagTypes[baseTag.type].validFiltersForBase.includes(tag.type)
  );

export const getPathFromTags = tags => {
  // make sure tags is an array, even if only one tag is passed
  const sanitizedTags = Array.isArray(tags) ? tags : [tags];

  // if we receive an empty array, we just return the base
  if (!sanitizedTags.length) {
    return '/wines/';
  }

  // sort tags by score
  const sortedTags = sortTagsByPrecedence(sanitizedTags);

  const [baseTag, ...secondaryTags] = sortedTags;

  // check if first tag is valid as a base, otherwise all tags are filters
  const filterTags = tagTypes[baseTag.type].isValidBase
    ? secondaryTags
    : sortedTags;

  // remove filter tags that aren't compatible
  const sanitizedFilterTags = sanitizeFilterTags(baseTag, filterTags);

  return createPath(baseTag, sanitizedFilterTags);
};

export const getUrlFromPath = path => `https://skincontact.com${path}`;

export const getTagsFromUrlFilters = (filters, defaultTags = []) => {
  // if we don't have any URL parameters/filters, use default tags
  if (Object.keys(filters).length < 1) {
    return defaultTags;
  }

  // convert filters to tags
  const filterTags = flatten(
    Object.keys(filters).map(type =>
      Array.isArray(filters[type])
        ? filters[type].map(slug => ({
            type,
            slug,
          }))
        : {
            type,
            slug: filters[type],
          }
    )
  );

  // make sure filters are compatible with base tag
  let sanitizedFilterTags = filterTags;

  if (defaultTags.length) {
    const sortedDefaultTags = sortTagsByPrecedence(defaultTags);
    const baseTag = sortedDefaultTags[0];

    // remove any invalid filters and sort
    sanitizedFilterTags = sanitizeFilterTags(baseTag, sanitizedFilterTags);
  }

  sanitizedFilterTags = sortTagsByPrecedence(sanitizedFilterTags);

  // combine tags
  return [...defaultTags, ...sanitizedFilterTags];
};
