import styled from 'styled-components';

import media from '@/styles/media';
import scale from '@/styles/scale';

export default styled.header.attrs(({ theme }) => ({
  h: theme.measure.header.height.map(height => scale(height)),
}))`
  z-index: 100;
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100%;
  background: ${props => props.theme.color.bg};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
  height: ${props => (props.isExpanded ? '100vh' : props.h[0])};
  transition: all 0.25s ease-out;

  ${media.md`
    height: ${props => (props.isExpanded ? '100vh' : props.h[1])};
  `}

  ${props =>
    props.transparentMode &&
    !props.isExpanded &&
    `
    && {
      height: 0;
      pointer-events: none;
    }
  `}

  /* for nicer rubberband-scrolling */
  ::after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: -500px;
    height: 500px;
    width: 100%;
    background: ${props => props.theme.color.bg};

    ${props =>
      props.transparentMode &&
      !props.isExpanded &&
      `
      height: 0;
    `}
  }
`;
